import { registerBlock } from "@smartfire/gutenberg-react";

// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
registerBlock("actualite", () => import(/* webpackChunkName: "block-actualite" */"/builds/demo-group/cadeaux-prives-website/application/src/actualite/block"));
registerBlock("bandeau-image", () => import(/* webpackChunkName: "block-bandeau-image" */"/builds/demo-group/cadeaux-prives-website/application/src/bandeau-image/block"));
registerBlock("button", () => import(/* webpackChunkName: "block-button" */"/builds/demo-group/cadeaux-prives-website/application/src/button/block"));
registerBlock("carrousel", () => import(/* webpackChunkName: "block-carrousel" */"/builds/demo-group/cadeaux-prives-website/application/src/carrousel/block"));
registerBlock("carrousel-child", () => import(/* webpackChunkName: "block-carrousel-child" */"/builds/demo-group/cadeaux-prives-website/application/src/carrousel-child/block"));
registerBlock("carrousel-child-content", () => import(/* webpackChunkName: "block-carrousel-child-content" */"/builds/demo-group/cadeaux-prives-website/application/src/carrousel-child-content/block"));
registerBlock("carrousel-child-image", () => import(/* webpackChunkName: "block-carrousel-child-image" */"/builds/demo-group/cadeaux-prives-website/application/src/carrousel-child-image/block"));
registerBlock("chronologie", () => import(/* webpackChunkName: "block-chronologie" */"/builds/demo-group/cadeaux-prives-website/application/src/chronologie/block"));
registerBlock("chronologie-child", () => import(/* webpackChunkName: "block-chronologie-child" */"/builds/demo-group/cadeaux-prives-website/application/src/chronologie-child/block"));
registerBlock("footer", () => import(/* webpackChunkName: "block-footer" */"/builds/demo-group/cadeaux-prives-website/application/src/footer/block"));
registerBlock("grid", () => import(/* webpackChunkName: "block-grid" */"/builds/demo-group/cadeaux-prives-website/application/src/grid/block"));
registerBlock("grid-child", () => import(/* webpackChunkName: "block-grid-child" */"/builds/demo-group/cadeaux-prives-website/application/src/grid-child/block"));
registerBlock("header", () => import(/* webpackChunkName: "block-header" */"/builds/demo-group/cadeaux-prives-website/application/src/header/block"));
registerBlock("home", () => import(/* webpackChunkName: "block-home" */"/builds/demo-group/cadeaux-prives-website/application/src/home/block"));
registerBlock("home-hero", () => import(/* webpackChunkName: "block-home-hero" */"/builds/demo-group/cadeaux-prives-website/application/src/home-hero/block"));
registerBlock("home-page", () => import(/* webpackChunkName: "block-home-page" */"/builds/demo-group/cadeaux-prives-website/application/src/home-page/block"));
registerBlock("image-content-insert", () => import(/* webpackChunkName: "block-image-content-insert" */"/builds/demo-group/cadeaux-prives-website/application/src/image-content-insert/block"));
registerBlock("key-numbers", () => import(/* webpackChunkName: "block-key-numbers" */"/builds/demo-group/cadeaux-prives-website/application/src/key-numbers/block"));
registerBlock("key-numbers-item", () => import(/* webpackChunkName: "block-key-numbers-item" */"/builds/demo-group/cadeaux-prives-website/application/src/key-numbers-item/block"));
registerBlock("offer", () => import(/* webpackChunkName: "block-offer" */"/builds/demo-group/cadeaux-prives-website/application/src/offer/block"));
registerBlock("offer-child", () => import(/* webpackChunkName: "block-offer-child" */"/builds/demo-group/cadeaux-prives-website/application/src/offer-child/block"));
registerBlock("offres", () => import(/* webpackChunkName: "block-offres" */"/builds/demo-group/cadeaux-prives-website/application/src/offres/block"));
registerBlock("offres-child", () => import(/* webpackChunkName: "block-offres-child" */"/builds/demo-group/cadeaux-prives-website/application/src/offres-child/block"));
registerBlock("post-filters", () => import(/* webpackChunkName: "block-post-filters" */"/builds/demo-group/cadeaux-prives-website/application/src/post-filters/block"));
registerBlock("post-list", () => import(/* webpackChunkName: "block-post-list" */"/builds/demo-group/cadeaux-prives-website/application/src/post-list/block"));
registerBlock("presentation-clients", () => import(/* webpackChunkName: "block-presentation-clients" */"/builds/demo-group/cadeaux-prives-website/application/src/presentation-clients/block"));
registerBlock("presentation-clients-child", () => import(/* webpackChunkName: "block-presentation-clients-child" */"/builds/demo-group/cadeaux-prives-website/application/src/presentation-clients-child/block"));
registerBlock("share-post", () => import(/* webpackChunkName: "block-share-post" */"/builds/demo-group/cadeaux-prives-website/application/src/share-post/block"));
registerBlock("text-content", () => import(/* webpackChunkName: "block-text-content" */"/builds/demo-group/cadeaux-prives-website/application/src/text-content/block"));
registerBlock("text-image", () => import(/* webpackChunkName: "block-text-image" */"/builds/demo-group/cadeaux-prives-website/application/src/text-image/block"));
registerBlock("video", () => import(/* webpackChunkName: "block-video" */"/builds/demo-group/cadeaux-prives-website/application/src/video/block"));
