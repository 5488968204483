import { SmartfireComponent } from "@smartfire/gutenberg-react";
import { createElement } from "@wordpress/element";
import { registerFormatType, applyFormat, removeFormat, getActiveFormat } from "@wordpress/rich-text";
import { InspectorControls, PanelColorSettings } from '@wordpress/block-editor';
import colorPalette from "./colorPalette";

const __ = SmartfireComponent.__;

export default function Colors(){
    [
        {
            name: 'color',
            title: __('Text color')
        }
    ].forEach(({ name, title }) => {
        const type = `advanced/${name}`

        registerFormatType(type, {
            title,
            tagName: 'span',
            className: name,
            attributes: {
                style: 'style'
            },
            edit({ isActive, value, onChange }) {
                let activeColor

                if (isActive) {
                    const activeFormat = getActiveFormat(value, type)
                    const style = activeFormat.attributes.style

                    activeColor = style.replace(new RegExp(`^${name}:\\s*`), '')
                }

                return (
                    createElement(InspectorControls, null,
                        createElement(PanelColorSettings, {
                            title,
                            initialOpen: true,
                            colorSettings: [
                                {
                                    value: activeColor,
                                    onChange: (color) => {
                                        if (color) {
                                            onChange(applyFormat(value, {
                                                type,
                                                attributes: {
                                                    style: `${name}:${color}`
                                                }
                                            }))
                                            return
                                        }

                                        onChange(removeFormat(value, type))
                                    },
                                    label: __('Apply color to selected text'),
                                    colors: colorPalette,
                                }
                            ]
                        })
                    )
                )
            }
        })
    })
};
