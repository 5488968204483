import style from "./colors.module.scss";

const colors = [
        { name: 'Red', color: style.red },
        { name: 'Cyan', color: style.cyan },
        { name: 'Yellow', color: style.yellow },
        { name: 'Black', color: style.black },
        { name: 'Dark Grey', color: style.darkGrey },
        { name: 'White', color: style.white },     
        { name: 'Grey', color: style.grey },     
    ];
export default colors;
